.app{
  height: 100vh;
  overflow: hidden;
  width: 100vw;
  
  .app-background{
    background: radial-gradient(ellipse at bottom, rgb(25, 25, 25) 0%, rgb(0, 0, 0) 100%);
    position: absolute;
    width: 100%;
    height: 100vh;
    z-index: -2;
    overflow: hidden;

    img{
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: -1;
    }
  }

  .header{
    height: 10vh;
  }
  
  .body{
    align-items: center;
    display: flex;
    height: 80vh;
  }
  
  .footer{
    height: 10vh;
  }

  .left-nav-bar{
    width: 10%;
  }

  .display-container{
    height: 100%;
    width: 80%;
  }

  .right-nav-bar{
    right: 0;
    width: 10%;
  }
}
