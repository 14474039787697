.battery{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .main-body{
    border: 10px solid white;
    display: flex;
    height: 100px;
    width: 90%;
  }

  .technology-container{
    width: 100%;
  }
  
  .technology-item{
    background-clip: padding-box;
    background-color: rgb(75, 200, 0);

    box-sizing: border-box;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    height: 100%;
    position: relative;
    width: 100%;
    
    &:hover:after{
      animation: fadeIn 0.5s;
      background-clip: padding-box;
      background-color: inherit;
      box-sizing: border-box;
      content: attr(title);
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      position: absolute;
      z-index: 10;
      width: 100%;
      height: 100%;
      text-align: center;
      word-break: break-all;
    }
  }
  
  .first-technology-item{
    border-color: transparent;
    border-style: solid;
    border-width: 10px 5px 10px 10px;
  }

  .nth-technology-item{
    border-color: transparent;
    border-style: solid;
    border-width: 10px 5px;
  }

  .last-technology-item{
    border-color: transparent;
    border-style: solid;
    border-width: 10px 10px 10px 5px;
  }

  .cap-container{
    .cap{
      border-top: 10px solid white;
      border-right: 10px solid white;
      border-bottom: 10px solid white;
      height: 50px;
      width: 10px;
    }
  }
}