.credits-page{
  font-family: $ShareTechMono;
  text-decoration: none;

  a{
    &:hover{
      color: white;
    }
  }

  .credits-body{
    height: 100%;
  }

  .table-container{
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  
  .accreditation-row{
    animation: scrollDown 10s linear infinite;
    display: flex;
    position: relative;
    width: 100%;
  }

  .accreditation-col{
    width: 100%;
  }
}