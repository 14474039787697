@import './project-list',
        './project-page';

.projects-modal{
  font-family: $ShareTechMono;

  ::-webkit-scrollbar{
    width: 10px;
  }
  
  ::-webkit-scrollbar-track{
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb{
    background: gray;
    border-radius: 10px;;
  }
}

.project-footer{
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 10%;
}

.home-button-container{
  font-size: 30px;
  
  svg:hover{
    color: white;
    cursor: pointer;
  }
}