.about-page{
  font-family: $ShareTechMono;

  ::-webkit-scrollbar{
    width: 10px;
  }
  
  ::-webkit-scrollbar-track{
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb{
    background: gray;
    border-radius: 10px;;
  }
  
  .about-info-container{
    overflow-y: scroll;
  }

  .row{
    display: flex;
    padding: 10px 0;
  }

  .column{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 50%;
  }

  .profile-container{
    img{
      border-radius: 50%;
      width: 50%;
    }
  }

  .description-container{
    text-align: center;
  }

  .attribute-table-container{
    text-align: center;

    table{
      width: 100%;

      th{
        background-color: rgb(100, 100, 100);
        color: white;
        padding: 5px 0;
        width: 50%;
      }

      td{
        padding: 5px 0;
        width: 50%;
      }
    }
  }

  .skills-section{
    ul{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      li{
        padding: 10px;
        text-align: center;
  
        i{
          font-size: 50px;
  
          &:hover{
            color: white;
          }
        }

        h3{
          padding-top: 5px;
        }
      }
    }
  }
}