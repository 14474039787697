@import './credits',
        './about',
        './projects/projects',
        './close-button';

.display-container{
  display: flex;
  align-items: center;
  justify-content: center;
  
  .display{
    animation: openUp 3s;
    background-color: rgba(0, 0, 0, 0.8);
    border: 1px solid gray;
    box-sizing: border-box;
    color: gray;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;
    position: relative;
    overflow: hidden;
    width: 80%;
  }
  
  .title-heading{
    font-size: 30px;
    padding: 20px 0;
    text-align: center;
    width: 100%;
  }
  
  .display-body{
    box-sizing: border-box;
    padding: 20px;
    width: 100%;
  }
}