@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@keyframes scrollDown {
  0% {
    top: -10%;
  }
  100% {
    top: 100%;
  }
}

@keyframes scrollFromLeft {
  0% {
    right: 100%;
  }

  100% {
    right: 0;
  }
}

@keyframes scrollFromRight {
  0% {
    left: 100%;
  }

  100% {
    left: 0;
  }
}

@keyframes nameScrollUp {
  0% {
    transform: translateY(-50%);
    top: 50vh;
  }

  50% {
    transform: translateY(-50%);
    top: 50vh;
  }

  100% {
    top: 0%;
  }
}

@keyframes openUp{
  0%{
    display: hidden;
    height: 0;
    padding: 0;
  }

  100%{
    height: 100%;
    padding: 20px;
  }
}