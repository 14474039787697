.close-button-container{
  color: gray;
  font-size: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;

  &:hover{
    color: white;
  }
}