.project-page{
  overflow-y: scroll;

  .row{
    display: flex;
  }

  .column{
    box-sizing: border-box;
    width: 50%;
  }

  .project-image-container{
    display: flex;
    align-items: center;
    position: relative;

    &:hover .project-page-image-overlay{
      display: flex;
    }
  }

  .project-image{
    object-fit: scale-down;
    width: 100%;
  }

  .project-page-image-overlay{
    background-color: rgba(50, 50, 50, 0.7);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    position: absolute;
  }

  .project-page-image-links{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    width: 100%;
  }
  
  .project-page-image-link{
    margin: 50px;
    
    svg:hover{
      color: white;
    }
  }

  .project-description{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 30px;
    text-align: center;
  }

  .display-technologies-container{
    width: 100%;

    .title{
      font-size: 30px;
      padding: 20px 0;
      text-align: center;
    }
  }
}