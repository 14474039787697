.right-nav-bar{
  text-align: center;

  .nav-link-icon{
    animation: scrollFromRight 2s;
    color: gray;
    font-family: $ShareTechMono;
    font-size: 20px;
    padding: 15px 0;
    position: relative;

    &:hover{
      color: white;
      cursor: pointer;
    }
    
    > a{
      text-decoration: none;
      
      &:link{
        color: inherit;
      }

      &:visited{
        color: inherit;
      }
    }
  }
}