.left-nav-bar{
  align-items: center;
  display: flex;
  flex-direction: column;
  
  .nav-link-icon{
    animation: scrollFromLeft 2s;
    font-size: 40px;
    padding: 10px 0;
    position: relative;
    
    svg{
      color: gray;
    }

    &:hover svg{
      color: white;
    }
  }
}