$Roboto: 'Roboto', sans-serif;
$ShareTechMono: 'Share Tech Mono', monospace;

@import './reset',
        './layout',
        './name/name',
        './left-nav-bar/left-nav-bar',
        './display/display',
        './right-nav-bar/right-nav-bar',
        './battery/battery',
        './animation/animation';

.red{
  background-color: red;
}

.brown{
  background-color: brown;
}

.gold{
  background-color: gold;
}

.orange{
  background-color: orange;
}

.light-blue{
  background-color: lightblue;
}

.blue{
  background-color: blue;
}

.dark-blue{
  background-color: darkblue;
}

.purple{
  background-color: purple;
}

.light-green{
  background-color: lightgreen;
}

.dark-green{
  background-color: darkgreen;
}