.project-list{
  height: 100%;
  overflow-y: scroll;

  .project-list-item{
    height: 100%;
    max-height: 200px;
    margin: 0 0 30px;
    position: relative;
  
    img{
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  
    &:hover .project-list-item-overlay{
      display: flex;
    }
  
    &:last-child{
      margin: 0;
    }
  }
  
  .unshifted-image{
    object-position: center top;
  }
  
  .shifted-image{
    object-position: unset;
  }
  
  .project-list-item-overlay{
    background-color: rgba(50, 50, 50, 0.7);
    box-sizing: border-box;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    position: absolute;
  }
  
  .project-list-item-title{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 50px;
    width: 60%;
  
    h1:hover{
      color: white;
      cursor: pointer;
    }
  }
  
  .project-list-item-links{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    width: 40%;
  }
  
  .project-list-item-link{
    margin: 20px;
    
    svg:hover{
      color: white;
    }
  }
}
